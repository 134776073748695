<template>
  <div id="userAgreement">
    <div class="common_layout">
      <div class="layout_left">
        <h3>{{ detail.title }}</h3>
        <div v-html="detail.mainText" class="w-e-text"></div>
      </div>
      <div v-if="detail.cateList && detail.cateList.length > 0" class="layout_right">
        <CommonMenu :headList="detail.cateList"></CommonMenu>
      </div>
    </div>
  </div>
</template>

<script>
import CommonMenu from '@/components/CommonMenu.vue'
export default {
  components: {
    CommonMenu,
  },
  data() {
    return {
      detail: {}
    }
  },
  mounted() {
    this.showPage()
  },
  methods: {
    showPage() {
      this.$API.UserAgreement().then(res => {
        if(res.data.code == 200) {
          this.detail = res.data.data
        }
      })
    }
  }
}
</script>

<style lang='scss'>
  #userAgreement {
    width: 1200px;
    margin: 0 auto;
    padding: 0 10px;

    .common_layout {
      max-height: calc(100% - 328px)
    }

    .layout_left {
      & > h3 {
        line-height: 46px;
        font-size: 32px;
        color: #1d2129;
        margin-bottom: 20px;
      }
    }

    .common_menu {
      margin-top: 0;
    }

    .w-e-text {
      padding: 10px 0;
    }
  }
</style>